.tw_commands {
  width: 100%;
}

.tw_commands .task {
  width: 10%;
}
.tw_commands .filter {
  width: 10%;
}
.tw_commands .command {
  width: 30%;
}
.tw_commands .description {
  width: 50%;
}
a {
  color: #1d4ed8;
}

.uk-logo {
  margin-left: 2rem;
}

.uk-logo img {
  height: 4rem;
  width: auto;
  transform: scaleX(-1);
}
.howto img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

.uk-section h2 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.uk-section h3 {
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
