// System fonts
@import "system-font-css/_system-font";

// Custom variables and variable overwrites.
@import "theme/variables";

// Import default variables and available mixins.
@import "uikit/variables-theme";
@import "uikit/mixins-theme";

// Custom mixin overwrites.
@import "theme/mixins";

// Import UIkit.
@import "theme/uikit";

// Other vendor styles
@import "syntax-highlighting/solarized_dark";

@import 'wingtask';
body {
  background: $bg-white;
}
.uk-navbar-container{
  background: $bg-white;
}

.uk-text-lead {
  color: $font-secondary;
}
.uk-search-input .uk-search-default{
  border: 1px solid $font-secondary;
}
.uk-card {
  border: 1px solid $font-secondary;
}

.uk-logo {
  font-family: $global-font-family;
  font-size: 2rem;
  img {
    padding-right: 0.25rem;
    padding-bottom: 0.40rem;
  }
}

/* Three image containers (use 25% for four, and 50% for two, etc) */
.stw-ImageCol {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clear floats after image containers */
.stw-ImageRow::after {
  content: "";
  clear: both;
  display: table;
}
